@import '../../../../scss/theme-bootstrap';

// Thinmint Loyalty panel styles.
.loyalty__panel__join {
  display: block;
  clear: both;
  &.is-member {
    display: none;
  }
  &.index-1 {
    #loyalty__page__about & {
      .loyalty__panel__join__title {
        display: none;
      }
    }
  }
  @include breakpoint($medium-up) {
  }
}

// Loyalty join, which appears in gnav dropdown.
.loyalty-join {
  text-align: center;
  &__heading {
    display: none;
  }

  // Rewards text is different for desktop vs mobile.
  &__rewards {
    text-transform: lowercase;
    &--desktop {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
    &--mobile {
      display: block;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  &__birthdate-label {
    @include h3;
    display: inline-block;
    line-height: 2.25;
    vertical-align: top;
  }
  &__button {
    display: block;
    &--join {
      width: 50%;
      margin: 1em auto;
    }
    &--no-thanks {
      margin-bottom: 0.5em;
    }
  }
  &__terms {
    text-transform: none;
  }
  .select-box {
    &__options {
      max-height: 15em;
    }
  }
}
